import $ = require("jquery");

import LsCallback from "~/Src/Components/Callback/Callback";
import LsGuardedDisable from "~/Src/Components/Utilities/GuardedDisable";
import { forceRedraw } from "~/Src/Components/Utilities/Utilities";

interface IOnEvent {
    (el: Element, data: { toggle: "tab", tab: LsJQuerySelector, target: LsJQuerySelector, state?: boolean, callback?: string }): void;
    (el: Element, data: { toggle: "class", target: LsJQuerySelector, cssClass: string, state?: boolean, callback?: string }): void;
    (el: Element, data: { toggle: "element", target: LsJQuerySelector, state?: boolean, callback?: string } & IEventData): void;
    (el: Element, data: { toggle: "submit", target?: LsJQuerySelector, callback?: string }): void;
    (el: Element, data: { toggle: "print" }): void;
    (el: Element, data: { toggle: "refresh" }): void;
}

interface IEventData {
    noActivation?: boolean;
}

export class LsToggle {
    protected static initialized = false;
    protected $document: JQuery<Document>;

    protected static buttonSelectors = ['a[role="button"]:not([aria-disabled="true"])', "button", 'input[type="button"]', 'input[type="submit"]'];
    protected static checkSelectors = ['input[type="checkbox"]', 'input[type="radio"]'];
    protected static textSelectors = ['input[type="date"]', 'input[type="datetime-local"]', 'input[type="email"]', 'input[type="password"]', 'input[type="tel"]', 'input[type="text"]', 'input[type="time"]', "textarea"];
    protected static fieldSelectors = ['input:not([type="hidden"])', "textarea", "select"];

    public constructor() {
        if (!LsToggle.initialized) {
            this.$document = $(document);

            this.$document.on("click", this.createSelectors(LsToggle.buttonSelectors), (e, { noActivation }: IEventData = {}) => {
                e.preventDefault();
                const $el = $(e.currentTarget);
                const data = $el.data();
                if (noActivation !== undefined) {
                    data.noActivation = noActivation;
                }
                this.onEvent(e.currentTarget, data as any);
            });
            this.$document.on("change", this.createSelectors(['input[type="checkbox"]']), (e, { noActivation }: IEventData = {}) => {
                const $el = $(e.currentTarget);
                const data = $el.data();
                if (noActivation !== undefined) {
                    data.noActivation = noActivation;
                }
                this.onEvent(e.currentTarget, data as any);
            });
            this.$document.on("change", this.createSelectors(['input[type="radio"]']), (e: LsJQueryEvent<HTMLInputElement>, { noActivation }: IEventData = {}) => {
                const $el = $(e.currentTarget);
                const $context = $el.closest("form, body");
                const context = $context.get(0);
                const name = e.currentTarget.name;
                const $radios = $context.find(`input[type="radio"][name="${name}"]`);
                const $radioSet = $radios.filter((i, el) => {
                    return $(el).closest("form, body").get(0) === context;
                });
                $radioSet.each((i, el: HTMLInputElement) => {
                    const $radio = $(el);
                    const data: LsJQueryData = $radio.data();
                    data.state = el.checked;
                    if (noActivation !== undefined) {
                        data.noActivation = noActivation;
                    }
                    this.onEvent(e.currentTarget, data as any);
                });
            });

            this.$document.on("change", this.createSelectors(["select"]), (e, { noActivation }: IEventData = {}) => {
                const $el = $(e.currentTarget);
                const data: LsJQueryData = $el.data();
                if (noActivation !== undefined) {
                    data.noActivation = noActivation;
                }
                const value = $el.val();
                if ($.isPlainObject(data.target)) {
                    for (const selector of Object.keys(data.target)) {
                        const values = data.target[selector].map(v => String(v));
                        const d = {
                            ...data,
                            target: selector,
                            state: values.indexOf(value) >= 0,
                        };
                        this.onEvent(e.currentTarget, d as any);
                    }
                }
                $el.data("prevValue", value);
            });

            this.$document.on("change", 'input[type="radio"][data-toggle="tab"]', (e: LsJQueryEvent<HTMLInputElement>, { noActivation }: IEventData = {}) => {
                e.preventDefault();
                const $tabs = $(`input[type="radio"][data-toggle="tab"][name="${e.currentTarget.name}"]`);
                $tabs.each((i, el: HTMLElement) => {
                    const $tab = $(el);
                    const data: LsJQueryData = $tab.data();
                    data.tab = el;
                    data.state = el === e.currentTarget;
                    if (noActivation !== undefined) {
                        data.noActivation = noActivation;
                    }
                    this.onEvent(e.currentTarget, data as any);
                });
            });

            LsToggle.initialized = true;
        }
    }

    protected createSelectors = (elements: Array<string>): string => {
        let selectors: Array<string> = [];
        for (let element of elements) {
            for (let toggle of ["class", "element", "submit", "print", "refresh"]) {
                selectors.push(`${element}[data-toggle="${toggle}"]`);
            }
        }
        return selectors.join(", ");
    }

    protected onEvent: IOnEvent = (el: Element, data): void => {
        switch (data.toggle) {
            case "tab":
                if ((data.tab !== undefined) && (data.target !== undefined)) {
                    $(data.tab).each((i, el) => LsToggle.toggleClass($(el).closest("[data-tab]"), "lsc-active", data.state));
                    $(data.target).each((i, el) => LsToggle.toggleElement($(el), data.state));
                    //$(data.target).find(this.fieldSelectors.join(", ")).each((i, el) => this.toggleProperty($(el), "disabled", !data.state));
                    if ((data.callback !== undefined) && data.state) {
                        LsCallback.call(data.callback, data.callbackParameters, el);
                    }
                }
                break;
            case "class":
                $(data.target).each((i, el) => LsToggle.toggleClass($(el), data.cssClass, data.state));
                if ((data.callback !== undefined) && data.state) {
                    LsCallback.call(data.callback, data.callbackParameters, el);
                }
                break;
            case "element":
                if (data.target !== undefined) {
                    $(data.target).each((i, el) => {
                        const $target = $(el);
                        LsToggle.toggleElement($target, data.state);
                        LsToggle.disableContent($target, data.state === undefined ? undefined : !data.state);
                        if (!data.noActivation) {
                            LsToggle.activateContent($target);
                        }
                    });
                    if ((data.callback !== undefined) && (data.state !== false)) {
                        LsCallback.call(data.callback, data.callbackParameters, el);
                    }
                }
                break;
            case "submit":
                $(data.target || el).each((i, el) => {
                    const $target = $(el);

                    let $form = $target.closest("form");

                    if ($form.length === 0) {
                        const id = $target.attr("form");
                        $form = $(`#${id}`);
                    }

                    if ($form.length > 0) {
                        LsToggle.submit($form);
                    }
                });
                if (data.callback !== undefined) {
                    LsCallback.call(data.callback, data.callbackParameters, el);
                }
                break;
            case "print":
                LsToggle.print();
                break;
            case "refresh":
                LsToggle.refresh();
                break;
        }
    }

    public static toggleClass($target: JQuery, cssClass: string, state?: boolean) {
        $target.toggleClass(cssClass, state);
    }

    public static toggleElement($target: JQuery, state?: boolean) {
        if (state === undefined) {
            state = $target.prop("hidden");
        }
        $target.prop("hidden", !state);
    }

    public static toggleProperty($target: JQuery, property: string, state?: boolean) {
        if (state === undefined) {
            state = !$target.prop(property);
        }
        $target.prop(property, state);
    }

    public static submit($target: JQuery) {
        $target.trigger("submit");
    }

    public static print() {
        window.print();
    }

    public static refresh() {
        window.location.reload(true);
    }

    public static activateContent($target: JQuery) {
        const $input = $target.not("[data-no-activation]").find(LsToggle.fieldSelectors.join(", ")).addBack(LsToggle.fieldSelectors.join(", ")).not("[data-no-activation]");
        if (($input.length !== 1) || !$target.is(":visible") || $input.is(":disabled")) {
            return;
        }
        const tabindex = $input.attr("tabindex");
        if (tabindex === "-1") {
            return;
        }
        if ($input.is(LsToggle.checkSelectors.join(", "))) {
            $input.prop("checked", true);
            $input.trigger("focus");
            $input.trigger("change");
        } else if ($input.is(LsToggle.textSelectors.join(", "))) {
            const $wrapper = $input.closest(".ls-field-wrapper");
            $wrapper.addClass("no-transition lsu-tsn-no");
            // focus event causes drawer to slide open rather than reveal in Chrome due to scrolling the element into view
            //$input.trigger("focus");
            $input.trigger("select");
            forceRedraw($input.get(0));
            $wrapper.removeClass("no-transition lsu-tsn-no");
        }
    }

    protected static disableContent($target: JQuery, state?: boolean) {
        if (state === undefined) {
            const $hidden = $target.closest("[hidden]");
            state = $hidden.length > 0;
        }
        const selector = [...LsToggle.buttonSelectors, ...LsToggle.fieldSelectors].join(", ");
        const $inputs = $target.find(selector).addBack(selector);
        if (state) {
            LsGuardedDisable.disable($inputs, "ToggleElement");
        } else {
            LsGuardedDisable.enable($inputs, "ToggleElement");
        }
    }
}

export default LsToggle;