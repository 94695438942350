/*
Copyright © 2013 Adobe Systems Incorporated.

Licensed under the Apache License, Version 2.0 (the “License”);
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an “AS IS” BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { Key } from "ts-keycode-enum";

import { uniqueId } from "~/Src/Components/Utilities/Utilities";

import "./AccessibleMegaMenu.scss";

interface IAccessibleMegaMenuOptions {
    navSelector: string; // CSS selector for topmost wrapper of navigation
    navTriggerSelector: string; // CSS selector for button-like element to toggle entire navigation
    menuSelector: string; // CSS selector for wrapper of individual menu within navigation
    menuTriggerSelector: string; // CSS selector for link/button element to toggle individual content
    menuPanelSelector: string; // CSS selector for individual menu content
    openClass: string; // CSS class for the open state
    openOnMouseover: boolean | (() => boolean); // whether menu should open on mouseover
    openDelay: number; // open delay when opening menu via mouseover
    closeDelay: number; // open delay when opening menu via mouseover
    //hoverClass: string; // CSS class for the hover state
    //focusClass: string; // CSS class for the focus state
}

class AccessibleMegaMenuDefaults implements IAccessibleMegaMenuOptions {
    public navSelector = "[data-amm-nav]";
    public navTriggerSelector = "[data-amm-nav-trigger]";
    public menuSelector = "[data-amm-menu]";
    public menuTriggerSelector = "[data-amm-menu-trigger]";
    public menuPanelSelector = "[data-amm-menu-panel]";
    public openClass = "lsc-amm--open"; // default css class for the open state
    public openOnMouseover = false; // default setting for whether menu should open on mouseover
    public openDelay = 150; // default open delay when opening menu via mouseover
    public closeDelay = 300; // default open delay when opening menu via mouseover
    //public hoverClass = "lsc-amm--hover"; // default css class for the hover state
    //public focusClass = "lsc-amm--focus"; // default css class for the focus state
}

export class AccessibleMegaMenu {
    protected get openOnMouseover() {
        if (typeof this.settings?.openOnMouseover === "function") {
            return this.settings.openOnMouseover();
        }
        return Boolean(this.settings?.openOnMouseover || false);
    }

    protected $nav: JQuery;
    protected $navTriggers: JQuery;
    protected $menus: JQuery;
    protected $menuPanels: JQuery;

    protected settings: IAccessibleMegaMenuOptions;

    protected focusTimeoutId: ReturnType<typeof window.setTimeout>;
    protected mouseTimeoutId: ReturnType<typeof window.setTimeout>;

    protected mouseFocused = false;
    protected justFocused = false;

    protected mouseHovered = false;

    public constructor(el: HTMLElement, options?: Partial<IAccessibleMegaMenuOptions>) {
        this.$nav = $(el);
        const initialized = !!this.$nav.data("amm");
        if (!initialized) {
            this.settings = $.extend({}, AccessibleMegaMenus.defaults, options, this.$nav.data(), this.$nav.data("options"));
            this.$nav.data("amm", this);

            this.$navTriggers = $(this.settings.navTriggerSelector);
            this.$menus = this.$nav.find(this.settings.menuSelector);
            this.$menuPanels = this.$nav.find(this.settings.menuPanelSelector);

            const ammp = new AccessibleMegaMenuPseudos();
            this.addAccessibilityAttributes();
            this.addEventListeners();
        }
    }

    protected addAccessibilityAttributes = () => {
        const navId = this.addUniqueId(this.$nav);

        this.$navTriggers.attr({ 
            "aria-expanded": false,
            "aria-pressed": false,
            "aria-controls": navId,
        });

        for (const el of this.$menus.toArray()) {
            const $menu = $(el);

            const $menuTrigger = $menu.find(this.settings.menuTriggerSelector);
            const triggerId = this.addUniqueId($menuTrigger);

            const $menuPanel = $menu.find(this.settings.menuPanelSelector);
            if ($menuPanel.length > 0) {
                const panelId = this.addUniqueId($menuPanel);

                $menuTrigger.attr({
                    "role": "button",
                    "aria-controls": panelId,
                    "aria-expanded": false,
                    "tabindex": 0,
                });

                $menuPanel.attr({
                    "role": "region",
                    "aria-expanded": false,
                    "aria-hidden": true,
                });

                $menuPanel.not("[aria-labelledby]").attr("aria-labelledby", triggerId);
            }
        }
    }

    protected addEventListeners = () => {
        this.$nav.on("focusin", `:focusable, ${this.settings.menuPanelSelector}`, this.onFocusIn);
        this.$nav.on("focusout", `:focusable, ${this.settings.menuPanelSelector}`, this.onFocusOut);
        this.$nav.on("keydown", this.onKeyDown);
        this.$nav.on("mouseover", this.onMouseOver);
        this.$nav.on("mouseout", this.onMouseOut);
        this.$nav.on("mousedown", this.onMouseDown);
        this.$nav.on("click", this.onClick);

        this.$navTriggers.on("click", this.onNavTriggerClick);

        //if (AccessibleMegaMenus.isTouch) {
        //    this.$nav.on("touchmove", this.touchmoveHandler);
        //}

        const $activeElement = $(AccessibleMegaMenus.document.activeElement);
        const $closestNav = $activeElement.closest(this.$nav);
        if ($closestNav.length > 0) {
            $activeElement.trigger("focusin");
        }
    }

    protected onFocusIn = (e: LsJQueryEvent) => {
        AccessibleMegaMenus.window.clearTimeout(this.focusTimeoutId);
        const $closestPanel = $(e.target).closest(this.settings.menuPanelSelector) as JQuery;
        this.justFocused = !this.mouseFocused || (!this.openOnMouseover && this.mouseFocused);
        this.mouseFocused = false;
        if (this.justFocused) {
            const $panels = this.$menuPanels.not($closestPanel).filter(`.${this.settings.openClass}`);
            if ($panels.length > 0) {
                this.openMenu(e);
            }
        }
    }

    protected onFocusOut = (e: LsJQueryEvent) => {
        this.justFocused = false;
        const $closestMenu = $(e.target).closest(this.settings.menuSelector);

        if (typeof (AccessibleMegaMenus.window as any).cvox?.Api?.getCurrentNode === "function") { // if ChromeVox is running
            this.focusTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
                (window as any).cvox.Api.getCurrentNode((node: Element) => {
                    if ($closestMenu.has(node).length > 0) { // the current node being voiced is in the menu
                        // keep the panel open
                        AccessibleMegaMenus.window.clearTimeout(this.focusTimeoutId);
                    } else {
                        this.focusTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
                            this.hideAllMenus(e);
                        }, 275); // TODO: what is this for? should it be moved to settings?
                    }
                });
            }, 25); // TODO: what is this for? should it be moved to settings?
        } else {
            this.focusTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
                if (!this.mouseFocused || (e as JQuery.EventBase).relatedTarget) {
                    this.hideAllMenus(e);
                }
            }, 300); // TODO: what is this for? should it be moved to settings?
        }
    }

    protected onKeyDown = (e: LsJQueryEvent) => {
        const $target = $(e.target) as JQuery;

        // TODO: handle arrow keys on form elements in a menu

        if (e.which === Key.Escape) {
            this.mouseFocused = false;
            this.hideAllMenus(e);
        } else {
            const $closestMenu = $target.closest(this.settings.menuSelector);
            const $closestPanel = $target.closest(this.settings.menuPanelSelector);
            const $tabbables = this.$nav.find(":tabbable");
            const insidePanel = ($closestMenu.length === 1) && ($closestPanel.length === 1);
            let found = false;

            if ([Key.UpArrow, Key.DownArrow, Key.LeftArrow, Key.RightArrow].indexOf(e.which) >= 0) {
                e.preventDefault();
                this.mouseFocused = false;

                let $setFocus: JQuery;
                if ((e.which === Key.UpArrow) || (e.which === Key.DownArrow)) {
                    if (insidePanel) {
                        const $tabbables = $closestMenu.find(":tabbable");
                        const index = this.getNewIndex($tabbables, $target, e.which === Key.DownArrow);
                        if (index >= 0) {
                            $setFocus = $tabbables.eq(index);
                        }
                    } else if (e.which === Key.UpArrow) {
                        this.hideAllMenus(e);
                    } else {
                        this.openMenu(e);
                        const $panels = $closestMenu.find(this.settings.menuPanelSelector);
                        $setFocus = $panels.find(":tabbable").first();
                    }
                } else if ((e.which === Key.LeftArrow) || (e.which === Key.RightArrow)) {
                    const $menus = this.$menus.filter(":visible");
                    const index = this.getNewIndex($menus, $closestMenu, e.which === Key.RightArrow);
                    if (index >= 0) {
                        const $newMenu = $menus.eq(index);
                        $setFocus = $newMenu.find(this.settings.menuTriggerSelector);
                    }
                }
                if ($setFocus?.length === 1) {
                    $setFocus.trigger("focus");
                    found = true;
                }
            } else if (e.which === Key.Tab) {
                this.mouseFocused = false;
                let $setFocus: JQuery;
                if (e.shiftKey && !insidePanel && $target.hasClass(this.settings.openClass)) {
                    this.hideAllMenus(e);
                    const $menus = this.$menus.filter(":visible");
                    const index = this.getNewIndex($menus, $closestMenu, !e.shiftKey);
                    if (index >= 0) {
                        const $menu = $menus.eq(index);
                        const $panels = $menu.find(this.settings.menuPanelSelector);
                        if ($panels.length > 0) {
                            this.toggleTriggersAndPanels($menu, true);
                            $setFocus = $menu.find(":tabbable").last();
                        }
                    }
                } else {
                    const index = this.getNewIndex($tabbables, $target, !e.shiftKey)
                    if (index >= 0) {
                        $setFocus = $tabbables.eq(index);
                    }
                }
                if ($setFocus?.length === 1) {
                    $setFocus.trigger("focus");
                    found = true;
                }
                if (found) {
                    e.preventDefault();
                }
            } else if ((e.which === Key.Space) || (e.which === Key.Enter)) {
                if (!insidePanel) {
                    const $panels = $closestMenu.find(this.settings.menuPanelSelector);
                    if ($panels.length === 1) {
                        e.preventDefault();
                        e.stopPropagation();
                        if ($target.hasClass(this.settings.openClass)) {
                            this.hideAllMenus(e);
                        } else {
                            this.openMenu(e);
                            this.justFocused = false;
                        }
                    }
                }
            }
        }

        this.justFocused = false;
    }

    protected onMouseOver = (e: LsJQueryEvent) => {
        AccessibleMegaMenus.window.clearTimeout(this.mouseTimeoutId);
        this.mouseHovered = true;

        if (this.openOnMouseover) {
            this.mouseTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
                const $target = $(e.target);
                this.openMenu(e);
            }, this.settings.openDelay);
        }
    }

    protected onMouseOut = (e: LsJQueryEvent) => {
        AccessibleMegaMenus.window.clearTimeout(this.mouseTimeoutId);
        this.mouseHovered = false;

        if (this.openOnMouseover) {
            this.mouseTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
                this.hideAllMenus(e);
            }, this.settings.closeDelay);
        }
    }

    protected onMouseDown = (e: LsJQueryEvent) => {
        const $target = $(e.target);
        const $closestPanel = $target.closest(this.settings.menuPanelSelector);
        const $focusable = $target.closest(":focusable");

        if (($closestPanel.length > 0) || ($focusable.length > 0)) {
            this.mouseFocused = true;
        }

        AccessibleMegaMenus.window.clearTimeout(this.mouseTimeoutId);
        this.mouseTimeoutId = AccessibleMegaMenus.window.setTimeout(() => {
            AccessibleMegaMenus.window.clearTimeout(this.focusTimeoutId);
        }, 1);
    }

    protected onClick = (e: LsJQueryEvent) => {
        const $target = $(e.target).closest(":tabbable");

        const $closestMenu = $target.closest(this.settings.menuSelector);
        const $closestPanel = $target.closest(this.settings.menuPanelSelector);
        const $panels = $closestMenu.find(this.settings.menuPanelSelector);
        const insidePanel = ($closestMenu.length === 1) && ($closestPanel.length === 1);

        // TODO: fix bug original in plugin - window losing and regaining focus triggers focusin which sets justfocused back to true
        if (!insidePanel && ($panels.length === 1) && (!this.mouseHovered || AccessibleMegaMenus.isTouch || !this.openOnMouseover)) {
            if (!$target.hasClass(this.settings.openClass)) {
                e.preventDefault();
                e.stopPropagation();
                this.openMenu(e);
                this.justFocused = false;
            } else if (this.justFocused) {
                e.preventDefault();
                e.stopPropagation();
                this.justFocused = false;
            } else if (!this.mouseHovered || (!AccessibleMegaMenus.isTouch && !this.openOnMouseover)) {
                e.preventDefault();
                e.stopPropagation();
                this.hideAllMenus(e);
            }
        }
    }

    protected onNavTriggerClick = () => {
        // TODO: handle multiple nav triggers
        const state = this.$navTriggers.attr("aria-expanded") !== "true";
        this.$navTriggers.attr({
            "aria-expanded": String(state),
            "aria-pressed": String(state),
        });
        this.$nav.toggleClass("lsc-amm--open", state);
    }

    protected addClickOutsideEventListener = () => {
        AccessibleMegaMenus.$document.on("mouseup touchend mspointerup pointerup", null, this.onOutsideClick); // null selector to satisfy jQuery type definitions
    }

    protected removeClickOutsideEventListener = () => {
        AccessibleMegaMenus.$document.off("mouseup touchend mspointerup pointerup", this.onOutsideClick);
    }

    protected onOutsideClick = (e: LsJQueryEvent) => {
        const $closestMenu = $(e.target).closest(this.settings.menuSelector);
        if ($closestMenu.length === 0) {
            e.preventDefault();
            e.stopPropagation();
            this.hideAllMenus(e);
        }
    }

    // TODO: replace with MutationObserver for performance
    //protected addDomAttributeModifiedEventListener = () => {
    //    // Narrator in Windows 8 automatically toggles the aria-expanded property on double tap or click.
    //    // To respond to the change to collapse the panel, we must add a listener for a DOMAttrModified event.
    //    const $panels = this.$menuPanels.filter('[aria-expanded="true"]');
    //    $panels.on("DOMAttrModified", this.onDomAttributeModified);
    //}
    //
    //protected removeDomAttributeModifiedEventListener = () => {
    //    this.$menuPanels.off("DOMAttrModified", this.onDomAttributeModified);
    //}
    //
    //// handle Windows 8 Narrator ExpandCollapse pattern
    //protected onDomAttributeModified = (e: LsJQueryEvent) => {
    //    const originalEvent: { [name: string]: any } = e.originalEvent;
    //    if ((originalEvent.attrName === "aria-expanded") && (originalEvent.newValue === "false") && $(e.target).hasClass(this.settings.openClass)) {
    //        e.preventDefault();
    //        e.stopPropagation();
    //        this.hideAllMenus(e);
    //    }
    //}

    protected openMenu = (e: LsJQueryEvent) => {
        this.removeClickOutsideEventListener();
        //this.removeDomAttributeModifiedEventListener();
        AccessibleMegaMenus.window.clearTimeout(this.focusTimeoutId);

        const $target = $(e.target) as JQuery;
        const $closestMenu = $target.closest(this.settings.menuSelector);
        if ($closestMenu.length > 0) {
            const $menus = $(this.settings.menuSelector).not($closestMenu);
            this.toggleTriggersAndPanels($menus, false);

            this.toggleTriggersAndPanels($closestMenu, true);

            const pageScrollPosition = AccessibleMegaMenus.document.documentElement.scrollTop;
            const openMenuTopPosition = $closestMenu.offset().top;
            if (pageScrollPosition > openMenuTopPosition) {
                AccessibleMegaMenus.document.documentElement.scrollTop = openMenuTopPosition;
            }

            if ((e.type === "mouseover") && ($closestMenu.length === 1)) {
                const $closestPanel = $target.closest(this.settings.menuPanelSelector);
                if ($closestPanel.length === 0) {
                    const $activeElement = this.$nav.find(AccessibleMegaMenus.document.activeElement);
                    if (($activeElement.length > 0) && $target.is(":tabbable")) {
                        $target.trigger("focus");
                        this.justFocused = false;
                    }
                }
            }

            this.addClickOutsideEventListener();
            //this.addDomAttributeModifiedEventListener();
        } else {
            this.toggleTriggersAndPanels(this.$nav, false);
        }
    }

    protected hideAllMenus = (e: LsJQueryEvent) => {
        this.removeClickOutsideEventListener();
        //this.removeDomAttributeModifiedEventListener();

        const $menus = this.$menus.has(`.${this.settings.openClass}`);
        if (!(e as JQuery.EventBase).relatedTarget || !($menus.is((e as JQuery.EventBase).relatedTarget) || $menus.has((e as JQuery.EventBase).relatedTarget).length > 0)) {
            if (((e.type === "mouseout") || (e.type === "focusout")) && ($menus.has(AccessibleMegaMenus.document.activeElement).length > 0)) {
                return;
            }

            this.toggleTriggersAndPanels($menus, false);

            //if (((e.type === "keydown") && (e.keyCode === Key.Escape)) || (e.type === "DOMAttrModified")) {
            if ((e.type === "keydown") && (e.keyCode === Key.Escape)) {
                AccessibleMegaMenus.window.setTimeout(() => {
                    //this.$nav.find(this.settings.menuPanelSelector).off("DOMAttrModified", this.onDomAttributeModified);
                    const $trigger = $menus.find(this.settings.menuTriggerSelector).first();
                    $trigger.trigger("focus");
                    this.justFocused = false;
                }, 99); // TODO: what is this for? should it be moved to settings?
            }
        } else if ($menus.length === 0) {
            this.toggleTriggersAndPanels(this.$nav, false);
        }
    }

    // TODO: what is the precedent for this variable name in other components?
    protected toggleTriggersAndPanels = ($ancestors: JQuery, opening: boolean) => {
        const $panels = $ancestors.find(this.settings.menuPanelSelector);
        if (!opening || ($panels.length > 0)) {
            const $triggers = $ancestors.find(this.settings.menuTriggerSelector);
            $triggers.toggleClass(this.settings.openClass, opening);
            $panels.toggleClass(this.settings.openClass, opening);
            $triggers.attr({ "aria-expanded": String(opening) });
            $panels.attr({ "aria-expanded": String(opening), "aria-hidden": String(!opening) });
        }
    }

    protected getNewIndex($elements: JQuery, $element: JQuery, forward: boolean) {
        const index = $elements.index($element);
        if ((!forward && (index > 0)) || (forward && (index < $elements.length - 1))) {
            return index + (forward ? 1 : -1);
        }
        return -1;
    }

    protected addUniqueId = ($el: JQuery) => {
        const el = $el.get(0);
        const id = uniqueId(el);
        el.id = id;
        return id;
    }
}

export class AccessibleMegaMenus {
    protected static _window: Window;
    public static get window() {
        return AccessibleMegaMenus._window || (AccessibleMegaMenus._window = window);
    }

    protected static _document: Document;
    public static get document() {
        return AccessibleMegaMenus._document || (AccessibleMegaMenus._document = document);
    }

    protected static _$document: JQuery<Document>;
    public static get $document() {
        return AccessibleMegaMenus._$document || (AccessibleMegaMenus._$document = $(AccessibleMegaMenus.document));
    }

    protected static _isTouch: boolean;
    public static get isTouch() {
        if (AccessibleMegaMenus._isTouch === undefined) {
            AccessibleMegaMenus._isTouch = AccessibleMegaMenus._isTouch = "ontouchstart" in window || !!(window.navigator as any).msMaxTouchPoints;
        }
        return AccessibleMegaMenus._isTouch;
    }

    public static readonly defaults = new AccessibleMegaMenuDefaults();

    public constructor() {
        $(() => {
            const $navs = $(AccessibleMegaMenus.defaults.navSelector);
            for (const el of $navs.toArray()) {
                const data = $(el).data();
                if (!data?.amm) {
                    const amm = new AccessibleMegaMenu(el);
                }
            }
        });
    }

    // Handle touch move event on menu
    //protected touchmoveHandler = () => {
    //    this.justMoved = true;
    //}
}

class AccessibleMegaMenuPseudos {
    protected static initialized = false;

    public constructor() {
        if (!AccessibleMegaMenuPseudos.initialized) {
            /* :focusable and :tabbable selectors originally from
            https://raw.github.com/jquery/jquery-ui/master/ui/jquery.ui.core.js */

            $.extend($.expr.pseudos, {
                data: $.expr.createPseudo((dataName: string) => el => !!$.data(el, dataName)),

                focusable: (el: Element) => {
                    const tabIndex = el.getAttribute("tabindex");
                    const isTabIndexNaN = isNaN(tabIndex as any ?? NaN);
                    return AccessibleMegaMenuPseudos.focusable(el, isTabIndexNaN);
                },

                tabbable: (el: Element) => {
                    const tabIndex = el.getAttribute("tabindex");
                    const isTabIndexNaN = isNaN(tabIndex as any ?? NaN);
                    return (isTabIndexNaN || (Number(tabIndex) >= 0)) && AccessibleMegaMenuPseudos.focusable(el, isTabIndexNaN);
                },
            });
        }
    }

    public static visible(el: Element) {
        if ($.expr.pseudos.visible(el)) {
            const $hidden = $(el).parents().addBack().filter((i, el) => $(el).css("visibility") === "hidden");
            return $hidden.length === 0;
        }
        return false;
    }

    public static focusable(el: Element, isTabIndexNaN: boolean) {
        if (AccessibleMegaMenuPseudos.visible(el)) {
            if (AccessibleMegaMenuPseudos.canBeDisabled(el)) {
                return !el.disabled;
            } else if (el instanceof HTMLAnchorElement && el.href) {
                return true;
            }
            return !isTabIndexNaN;
        }
        return false;
    }

    protected static canBeDisabled(el: Element): el is HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLButtonElement {
        return [HTMLInputElement, HTMLSelectElement, HTMLTextAreaElement, HTMLButtonElement].some(T => el instanceof T);
    }
}

export default AccessibleMegaMenus;