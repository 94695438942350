import $ = require("jquery");

import LsCallback from "~/Src/Components/Callback/Callback";
import LsGuardedDisable from "~/Src/Components/Utilities/GuardedDisable";
import LsToggle from "~/Src/Components/Toggle/Toggle";
import LsTransition from "~/Src/Components/Transition/Transition";
import { forceRedraw } from "~/Src/Components/Utilities/Utilities";

import "./Drawer.scss";

interface IEventData {
    noActivation?: boolean;
    noTransition?: boolean;
    noDisabling?: boolean;
}

interface IToggleData extends IEventData {
    opening?: boolean;
}

export class LsDrawer {
    protected static initialized = false;
    protected static buttonSelectors = ['a[role="button"]:not([aria-disabled="true"])', "button", 'input[type="button"]', 'input[type="submit"]'];
    protected static fieldSelectors = ['input:not([type="hidden"])', "textarea", "select"];

    public constructor() {
        if (!LsDrawer.initialized) {
            const $document = $(document);

            $(() => {
                this.toggleInitialDrawers();
            });
            $document.on("ajaxPageLoad", () => {
                this.toggleInitialDrawers();
            });

            $document.on("click", this.createSelectors(LsDrawer.buttonSelectors), (e: LsJQueryEvent, { noActivation, noTransition, noDisabling }: IEventData = {}) => {
                e.preventDefault();
                const $handle = $(e.currentTarget);
                const data: LsJQueryData = $handle.data();
                LsDrawer.toggle(data["target"], $.extend({ noActivation, noTransition, noDisabling }, data));
                $handle.trigger("blur");

                if ((data !== undefined) && ("callback" in data)) {
                    LsCallback.call(data["callback"], data["callbackParameters"], e.currentTarget);
                }
            });

            $document.on("change", this.createSelectors(['input[type="checkbox"]']), (e: LsJQueryEvent, { noActivation, noTransition, noDisabling }: IEventData = {}) => {
                const $handle = $(e.currentTarget);
                const data: LsJQueryData = $handle.data();
                LsDrawer.toggle(data["target"], $.extend({ noActivation, noTransition, noDisabling }, data));

                if ((data !== undefined) && ("callback" in data)) {
                    LsCallback.call(data["callback"], data["callbackParameters"], e.currentTarget);
                }
            });

            $document.on("change", this.createSelectors(['input[type="radio"]']), (e, { noActivation, noTransition, noDisabling }: IEventData = {}) => {
                const $handle = $(e.currentTarget);
                const $context: JQuery = $handle.closest("form, body");
                const context = $context.get(0);
                const name = $handle.attr("name");
                const $radios = $context.find(`input[type="radio"][name="${name}"]`);
                const $radioSet = $radios.filter((i, el: Element): boolean => {
                    return ($(el) as JQuery).closest("form, body").get(0) === context;
                });
                $radioSet.each((i, el: HTMLInputElement) => {
                    const $radio = $(el);
                    const data: LsJQueryData = $radio.data();
                    LsDrawer.toggle(data["target"], $.extend({ opening: el.checked, noActivation, noTransition, noDisabling }, data));

                    if ((data !== undefined) && ("callback" in data)) {
                        LsCallback.call(data["callback"], data["callbackParameters"], el);
                    }
                });
            });

            // don't set focus on label
            $document.on("mousedown", "[data-drawer-checkbox-label]", e => {
                e.preventDefault();
            });

            // don't set focus on checkbox
            $document.on("click", "[data-drawer-checkbox-label]", e => {
                if (e.target === e.currentTarget) {
                    e.preventDefault();

                    const $input = $(e.currentTarget).find("input");
                    const isChecked = $input.prop("checked");
                    $input.prop("checked", !isChecked);
                    $input.trigger("change");
                }
            });

            LsDrawer.initialized = true;
        }
    }

    public static open = (selector: LsJQuerySelector): void => {
        const $drawers = $(selector);
        for (const el of $drawers.toArray()) {
            const $drawer = $(el);
            if (!$drawer.hasClass("lsc-open")) {
                LsDrawer.toggle($drawer, { opening: true });
            }
        }
    }

    public static close = (selector: LsJQuerySelector): void => {
        const $drawers = $(selector) as JQuery;
        for (const el of $drawers.toArray()) {
            const $drawer = $(el);
            if ($drawer.hasClass("lsc-open")) {
                LsDrawer.toggle($drawer, { opening: false });
            }
        }
    }

    public static toggle = (selector: LsJQuerySelector, { opening, noActivation, noTransition, noDisabling }: IToggleData = {}): void => {
        const $drawers = $(selector);

        $drawers.removeAttr("data-toggle-on-load");

        let $handles: JQuery;
        if (typeof selector === "string") {
            $handles = $(`[data-toggle="drawer"][data-target="${selector}"]`);
        }
        if (!$handles || ($handles.length === 0)) {
            $handles = $('[data-toggle="drawer"]').filter((i, handle): boolean => {
                const data: LsJQueryData = $(handle).data();
                if (data.target) {
                    const $targets = $(data.target);
                    for (const target of $targets.toArray()) {
                        for (const drawer of $drawers.toArray()) {
                            if (drawer === target) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            });
        }
        if ($handles && ($handles.length > 0)) {
            for (const el of $handles.toArray()) {
                const $handle = $(el);
                const handleOpening = opening === undefined ? !$handle.hasClass("lsc-open") : opening;
                $handle.toggleClass("lsc-open", handleOpening);
            }
        }

        for (const target of $drawers.toArray()) {
            const $target = $(target);
            if ((opening === undefined) || (!opening === $target.hasClass("lsc-open"))) {
                const drawerOpening = opening === undefined ? !$target.hasClass("lsc-open") : opening;
                const selector = [...LsDrawer.buttonSelectors, ...LsDrawer.fieldSelectors].join(", ");
                const $inputs = $target.find(selector).addBack(selector);
                if (!noTransition && LsTransition.isSupported) {
                    $target.removeClass("lsc-closed");
                    const height = target.scrollHeight;
                    const duration = Math.round(39 * Math.pow(height, .44));
                    $target.css("transition-duration", `${duration}ms`);

                    if (drawerOpening) {
                        $target.css("height", height);
                        if (!noDisabling) {
                            LsGuardedDisable.enable($inputs, "drawer");
                        }
                        // do not by default set focus on field when drawer opens because mobile keyboard can cover field - per Alonna 2019-10-01
                        // noActivation can be undefined
                        if (noActivation === false) {
                            LsToggle.activateContent($target);
                        }
                        $target.trigger("drawerOpen");
                    } else {
                        const currentHeight = target.offsetHeight;
                        $target.addClass("no-transition lsu-tsn-no");
                        $target.css("height", currentHeight);
                        forceRedraw(target);
                        $target.removeClass("no-transition lsu-tsn-no");
                        $target.css("height", 0);
                        if (!noDisabling) {
                            LsGuardedDisable.disable($inputs, "drawer");
                        }
                        $target.trigger("drawerClose");
                    }

                    $target.on("transitionend", { opening: drawerOpening } as any, LsDrawer.onTransitionEnd);
                } else {
                    $target.toggleClass("lsc-closed", !drawerOpening);
                    if (drawerOpening) {
                        if (!noDisabling) {
                            LsGuardedDisable.enable($inputs, "drawer");
                        }
                        // do not by default set focus on field when drawer opens because mobile keyboard can cover field - per Alonna 2019-10-01
                        // noActivation can be undefined
                        if (noActivation === false) {
                            LsToggle.activateContent($target);
                        }
                    } else {
                        const $focused = $target.find(":focus");
                        if ($focused.length > 0) {
                            $focused.trigger("blur");
                        }
                        if (!noDisabling) {
                            LsGuardedDisable.disable($inputs, "drawer");
                        }
                    }
                }

                $target.toggleClass("no-transition lsu-tsn-no", !!noTransition); // noTransition can be undefined
                $target.toggleClass("lsc-open", drawerOpening);
                forceRedraw(target);
                $target.removeClass("no-transition lsu-tsn-no");
            }
        }
    }

    protected createSelectors = (elements: Array<string>): string => {
        const selectors = elements.map(element => `${element}[data-toggle="drawer"]`);
        return selectors.join(", ");
    }

    protected static onTransitionEnd = (e: LsJQueryEvent<Element, { opening: boolean }>) => {
        if (e.target === e.currentTarget) {
            const $target = $(e.currentTarget);
            $target.addClass("no-transition lsu-tsn-no");
            $target.css("height", "");
            $target.toggleClass("lsc-closed", !e.data || !e.data.opening);
            forceRedraw(e.currentTarget);
            $target.removeClass("no-transition lsu-tsn-no");
            $target.off("transitionend", LsDrawer.onTransitionEnd);
        }
    }

    protected toggleInitialDrawers = (): void => {
        const $drawers = $("[data-drawer][data-toggle-on-load]");
        if ($drawers.length > 0) {
            $drawers.each((i, el: Element) => {
                LsDrawer.toggle(el);
            });
        }
    }
}

export default LsDrawer;