import $ = require("jquery");

export class LsGuardedDisable {
    public static enable(selector: LsJQuerySelector, caller: string) {
        let $elements = $(selector);
        $elements = $elements.filter(`[data-disabled-by~="${caller}"]`);
        if ($elements.length > 0) {
            for (const el of $elements.toArray()) {
                const $el = $(el);

                const disabledBy = $el.attr("data-disabled-by");
                let callers = disabledBy.split(/\s+/);
                callers = callers.filter(v => v && (v !== caller));
                if (callers.length > 0) {
                    $el.attr("data-disabled-by", callers.join(" "));
                } else {
                    $el.removeAttr("data-disabled-by");

                    if (el.disabled === true) {
                        el.disabled = false;
                    } else if (el.disabled === false) {
                        // do nothing
                    } else {
                        $el.attr("aria-disabled", "false");
                    }
                }
            }
        }
    }

    public static disable(selector: LsJQuerySelector, caller: string) {
        const $elements = $(selector);
        if ($elements.length > 0) {
            for (const el of $elements.toArray()) {
                const $el = $(el);

                const disabledBy = $el.attr("data-disabled-by");
                $el.attr("data-disabled-by", disabledBy ? `${disabledBy} ${caller}` : caller);

                if (el.disabled === false) {
                    el.disabled = true;
                } else if (el.disabled === true) {
                    // do nothing
                } else {
                    $el.attr("aria-disabled", "false");
                }
            }
        }
    }
}

export default LsGuardedDisable;