import $ = require("jquery");

export class LsToggleClass {
    protected static initialized = false;

    public constructor() {
        if (!LsToggleClass.initialized) {
            this.toggleClass("[data-toggle-class]");

            $(() => {
                this.toggleClass("[data-toggle-class]");
            });

            $(document).on("input blur", "[data-toggle-class]", e => {
                this.toggleClass(e.target);
            });

            LsToggleClass.initialized = true;
        }
    }

    protected toggleClass = (selector: LsJQuerySelector) => {
        const $elements = $(selector);
        for (const el of $elements.toArray()) {
            const $el = $(el);
            $el.toggleClass($el.data("toggleClass"), this.compareValue($el));
        }
    }

    protected compareValue = ($input: JQuery) => {
        var value = ($input.val() as string).trim();
        var condition = $input.data("toggleOn");
        switch (condition) {
            case "empty":
            case "":
                return value === "";
            case "filled":
            case "!empty":
                return value !== "";
            default:
                return value === condition;
        }
    }
}

export default LsToggleClass;